import { Button } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import {
	text_xs,
	text_sm,
	text_base,
	text_xs_bold,
	text_sm_bold,
	text_base_bold,
} from "../../style/styles";
import InfoLineLabel from "./components/InfoLineLabel";

const smallScreenWidth = 1200;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	:not(:last-of-type) {
		padding-bottom: 20px;

		@media only screen and (min-width: ${smallScreenWidth}px) {
			padding-bottom: 39px;
		}
	}
`;

const InfoElementLabel = styled.h3`
	color: #36312e;
	${text_sm_bold} //font
    @media only screen and (min-width: ${smallScreenWidth}px) {
		${text_base_bold}//font
	}
`;

const InfoElementText = styled.p`
	color: #36312e;
	${text_sm} //font
    @media only screen and (min-width: ${smallScreenWidth}px) {
		${text_base}
	}
`;

const InfoElementLink = styled.a`
	color: var(--blaze600);
	${text_sm} //font
	white-space: pre-wrap;
	@media only screen and (min-width: ${smallScreenWidth}px) {
		${text_base}
	}
`;

const InfoElementButton = styled(Button)`
	display: inline;
	color: var(--blaze600);
	${text_sm} //font
    background: none;
	padding: 0;
	border: none;
	align-items: left;
	//align-content: left;
	vertical-align: baseline;
	text-transform: none;
	text-align: left;
	cursor: pointer;
	@media only screen and (min-width: ${smallScreenWidth}px) {
		${text_base}
	}
`;

const InfoElement = ({
	type,
	headerText,
	text,
	url = null,
	clickEvent = null,
	infoList = [],
	buttonList = [],
}) => {
	return (
		<Container>
			<InfoElementLabel>{headerText}</InfoElementLabel>
			{!url && text && <InfoElementText>{text}</InfoElementText>}
			{infoList.length > 0 && (
				<ul>
					{infoList.map((el, idx) => (
						<li key={"infoListEl_" + headerText + "_" + idx}>
							<InfoElementText>{el}</InfoElementText>
						</li>
					))}
				</ul>
			)}
			{buttonList.length > 0 && (
				<ul>
					{buttonList.map((el, idx) => (
						<li>
							<InfoElementButton
								key={"infoListEl_" + headerText + "_" + idx}
								onClick={el.func}
							>
								{el.text}
							</InfoElementButton>
						</li>
					))}
				</ul>
			)}

			{text && url && (
				<InfoElementLink href={url} target={"_blank"}>
					{text}
				</InfoElementLink>
			)}
		</Container>
	);
};

export default InfoElement;
