import React from "react";
import styled from "styled-components";
import OpenMap from "../components/OpenMap/openMap";

const Container = styled.div`
	display: flex;
	width: 100vw;
`;

const SearchPage2 = () => {
	return (
		<Container>
			<div></div>
			<OpenMap></OpenMap>
		</Container>
	);
};
export default SearchPage2;
