import CircularProgress from "@material-ui/core/CircularProgress";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import * as R from "ramda";
import React, {
	useState,
	useEffect,
	useCallback,
	useContext,
	useMemo,
	useRef,
} from "react";
import { useTranslation } from "react-i18next";
import currentPositionIcon from "../../assets/images/current-position-icon.png";
import getMuseumsInsideBounds from "../../helpers/getMuseumsInsideBounds";
import LocationButton from "../LocationButton";
import SearchOnMoveCheckbox from "../SearchOnMoveCheckbox/searchOnMoveCheckbox";
import { mapStyle } from "../Theme/theme";
import MuseumMarkerClusterer from "./MuseumMarkerClusterer";
import { Settings } from "../../config/Settings";
import { Store } from "../../Store";

const DEFAULT_ZOOM = 10;
const DEFAULT_GEOLOCATION_ZOOM = 13;

const Map = ({
	museums = [],
	listMuseums = [],
	isWide,
	activeMarker,
	isSearching,
	setData,
	setActiveMarker,
	active,
	searchTerm,
	setSearchTerm,
	setBounds,
	searchOnMove,
	setSearchOnMove,
}) => {
	const { state, dispatch } = useContext(Store);
	const { t } = useTranslation("common");
	const [showInfoWindow, setShowInfoWindow] = useState(false);
	const [map, setMap] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const mapRef2 = useRef();
	mapRef2.current = map;
	const stateRef = useRef(state);
	const museumsRef = useRef();
	museumsRef.current = museums;
	const [isOnCurrentPosition, setIsOnCurrentPosition] = useState(false);
	const [boundsApplied, setBoundsApplied] = useState(true);
	const setBoundsAppliedRef = useRef(setBoundsApplied);
	//const [searchOnMove, setSearchOnMove] = useState(false);
	const { lat, lng } = useMemo(() => state.user, [state.user]);
	const initialCenter = useMemo(() => {
		return {
			lat,
			lng,
		};
	}, [lat, lng]);

	const onMarkerClick = useCallback(
		selectedMarker => {
			const markerLocation = {
				lat: selectedMarker.position.lat,
				lng: selectedMarker.position.lng,
			};
			mapRef2 && mapRef2.current.setCenter(markerLocation);
			mapRef2 && mapRef2.current.setZoom(DEFAULT_GEOLOCATION_ZOOM);
			//setMCenter(markerLocation);
			setActiveMarker(selectedMarker);
		},
		[setActiveMarker],
	);

	// hide info window and set active marker for the map details section
	const onMapClicked = () => {
		//console.log("onMapClicked")
		if (showInfoWindow) {
			setShowInfoWindow(false);
		}

		if (!R.isEmpty(activeMarker) || true) {
			//DEBUG HACK
			//console.log("onMAPCLICKED setActiveMarker: ", activeMarker); //DEBUG
			//console.log("onMapClicked: ", evt)
			setActiveMarker({});
		}
	};

	// fit all markers on the screen

	const onDragEnd = () => {
		if (searchOnMove && mapRef2.current) {
			setBounds(mapRef2.current.getBounds());
		}
	};

	const handleCenterChange = () => {
		//console.log("handleCenterChange: ")
		//console.dir(mapRef2);
		if (isOnCurrentPosition) {
			setIsOnCurrentPosition(false);
		}
	};

	const handleZoomChange = () => {
		//console.log("handleZoomChange")
		if (mapRef2.current && searchOnMove) {
			setBounds(mapRef2.current.getBounds());
		}
	};

	const additionalProps = searchOnMove
		? { onZoomChanged: handleZoomChange }
		: {};

	useEffect(() => {
		//console.count("useEffect fit")
		const fitBounds = map => {
			//console.log("fitBounds")
			let _museums = museums;
			if (state.settings.searchNearby) {
				_museums = listMuseums;
			}
			const bounds = new window.google.maps.LatLngBounds();
			if (stateRef.current.user.lat && stateRef.current.user.lng) {
				bounds.extend(
					new window.google.maps.LatLng(
						stateRef.current.user.lat,
						stateRef.current.user.lng,
					),
				);
			}
			_museums.forEach(mus => {
				if (mus.position.lat && mus.position.lng) {
					const loc = new window.google.maps.LatLng(
						mus.position.lat,
						mus.position.lng,
					);
					bounds.extend(loc);
				}
			});
			map.fitBounds(bounds);
		};

		if (
			/*!searchOnMove &&*/ museums &&
			map &&
			(boundsApplied || state.settings.searchNearby)
		) {
			//console.log("state.settings.searchNearby")

			setBoundsApplied(false);
			fitBounds(map);
			const nearbyLogic = async () => {
				console.count("nearbyLogic");
				await dispatch({
					type: "SET_SETTINGS_SEARCHNEARBY",
					payload: false,
				});
				/*isWide && */ setSearchOnMove(true);
			};
			if (state.settings.searchNearby) {
				setTimeout(nearbyLogic, 10);
			}
		}
	}, [
		museums,
		searchOnMove,
		map,
		boundsApplied,
		state.settings.searchNearby,
		listMuseums,
	]);

	/* What did this do?
	useEffect(() => {
		//!searchOnMove && setBounds(null);
		if (searchOnMove && mapRef2.current) {
			setBounds(mapRef2.current.getBounds());
		}
		
	}, [searchOnMove]);
	*/

	useEffect(() => {
		console.count(`museums length: ${museums.length},  call count: `);
		if (museums.length && !searchOnMove && !state.settings.searchNearby) {
			console.count("Applying bounds");
			setBoundsAppliedRef.current(true);
		}
	}, [museums, searchTerm]);

	//console.log("isLoading?", isLoading);
	return (
		<div
			style={{
				width: "100%",
				height:
					"100%" /*!R.isEmpty(activeMarker) && !isWide ? '60%' : '100%',*/,
				transition: "height 0.8s",
				justifyContent: "center",
				alignItems: "center",
				display: isWide || (!isWide && active) ? "flex" : "none",
			}}
		>
			<LoadScript
				googleMapsApiKey={Settings.GOOGLE_API_KEY}
				loadingElement={<CircularProgress />}
				onLoad={() => setIsLoading(false)}
			>
				<GoogleMap
					mapContainerStyle={{
						width: "100%",
						height: "100%",
						borderRadius: isWide ? "24px" : "0",
					}}
					onDragEnd={onDragEnd}
					center={initialCenter}
					zoom={DEFAULT_ZOOM}
					onClick={onMapClicked}
					onLoad={map => {
						setMap(map);
						setIsLoading(false);
					}}
					options={{
						streetViewControl: false,
						fullscreenControl: false,
						mapTypeControl: false,
						zoomControl: isWide,
						styles: mapStyle,
						clickableIcons: false,
					}}
					onCenterChanged={handleCenterChange}
					{...additionalProps}
				>
					<MuseumMarkerClusterer
						museums={museums}
						activeMarker={activeMarker}
						onMarkerClick={onMarkerClick}
					/>
					{false /* TODO REACTIVATE */ && lat && lng && (
						<SearchOnMoveCheckbox
							searchOnMove={searchOnMove}
							setSearchOnMove={setSearchOnMove}
						/>
					)}
					<LocationButton
						isWide={isWide}
						handleCurrentLocation={() => {}}
						isOnCurrentPosition={isOnCurrentPosition}
						setIsOnCurrentPosition={setIsOnCurrentPosition}
						setSearchTerm={setSearchTerm}
					/>

					{state.user.lat && state.user.lng && (
						<Marker
							clickable={false}
							//title={t("general.currentLocation")}
							position={{ lat: state.user.lat, lng: state.user.lng }}
							icon={{
								url: currentPositionIcon,
								anchor: { x: 12, y: 12 },
								scaledSize: { width: 25, height: 25 },
							}}
						></Marker>
					)}
				</GoogleMap>
			</LoadScript>
		</div>
	);
};

export default React.memo(Map);
