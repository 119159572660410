import React from "react";
import styled from "styled-components";
import markerIcon from "../../assets/images/markerIcon.png";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const DefaultIcon = styled.img`
	width: 30px;
`;

const MarkerLabel = styled.p`
	font-family: var(--akkurat);
	text-align: center;
	font-weight: 700;
	text-shadow: 0 0 10px #fff;
`;

export const DefaultMarkerStyle = ({ name }) => {
	return (
		<Container>
			<DefaultIcon src={markerIcon} />
			{/*<MarkerLabel>{name}</MarkerLabel>*/}
		</Container>
	);
};
