import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import HomePage from "./pages/HomePage";
import SearchPage from "./pages/SearchPage";
import DetailsPage from "./pages/DetailsPage";
import NotFoundPage from "./pages/NotFoundPage";
import CategoriesPage from "./pages/CategoriesPage";
import ListPage from "./pages/ListPage";
import TestPage from "./pages/TestPage";
import Header from "./components/Header/header";
import Footer from "./components/Footer/footer";
import styled from "styled-components";
import BottomMenu from "./components/BottomMenu/bottomMenu";
import { Helmet } from "react-helmet";
import SearchPage2 from "./pages/SearchPage2";

const description =
	"Museumsguiden gir deg oversikt over museer i Norge. Søk på museum eller finn museer i nærheten av deg ved hjelp av kartet. Museumsguiden er utviklet av KulturIT.";
const url = "https://www.museumsguiden.no";
const title = "Museumsguiden.no";

const Routes = () => {
	const PageWrapper = ({ children, hasFooter = true }) => {
		return (
			<div
				style={{
					//display: "flex",
					//flexDirection: "column",
					height: "min-content",
					position: "relative",
					width: "100%",
					overflow: "auto",
					/*backgroundColor: "var(--Museumsbakgrunn)"*/
				}}
			>
				<Header />
				{children}
				{hasFooter && <Footer />}
				<BottomMenu />
			</div>
		);
	};
	return (
		<BrowserRouter>
			<Helmet
				meta={[
					{
						name: "description",
						property: "og:description",
						content: description,
					},
					{ property: "og:title", content: title },
					{ property: "og:url", content: url },
					/*{ property: "og:image", content: image },*/
					/*{ property: "og:image:type", content: "image/png" },*/
					/*{ property: "twitter:image:src", content: seo.image },*/
					{ property: "twitter:title", content: title },
					{ property: "twitter:description", content: description },
				]}
			>
				<title>Museumsguiden.no</title>
			</Helmet>
			<div
				style={{
					//display: "flex",
					//flexDirection: "column",
					height: "min-content",
					position: "relative",
					width: "100%",
					backgroundColor: "var(--Museumsbakgrunn)",
					maxWidth: "100vw",
				}}
			>
				<Header />
				<Switch>
					<Route
						exact
						path="/"
						render={props => (
							<>
								<HomePage props={props} />
								<Footer />
							</>
						)}
					/>

					<Route
						exact
						path="/museums"
						render={props => <SearchPage props={props} />}
					/>

					<Route
						exact
						path="/museums/:museumId"
						render={props => <DetailsPage />}
					/>

					<Route
						exact
						path="/categories"
						render={props => (
							<>
								<CategoriesPage isCategories={true} />
								<Footer />
							</>
						)}
					/>

					<Route
						exact
						path="/counties"
						render={props => (
							<>
								<CategoriesPage isCategories={false} />
								<Footer />
							</>
						)}
					/>

					<Route
						exact
						path="/list"
						render={props => (
							<>
								<ListPage props={props} />
								<Footer />
							</>
						)}
					/>

					<Route
						exact
						path="/openmap"
						render={props => <SearchPage2 props={props} />}
					/>

					<Route
						exact
						path="/test"
						render={props => <TestPage props={props} />}
					/>

					{/* Catch all routes */}
					<Route component={NotFoundPage} />
				</Switch>
				<BottomMenu />
			</div>
		</BrowserRouter>
	);
};
/*

          <Route exact path="/" component={HomePage} />
          <Route exact path="/museums" component={SearchPage} />
          <Route exact path="/museums/:museumId" component={DetailsPage} />
          <Route exact path="/categories" render={(props) => (<CategoriesPage isCategories={true} />)} />
          <Route exact path="/counties" render={(props) => (<CategoriesPage isCategories={false} />)} />
          <Route exact path="/list" component={ListPage} />
          <Route exact path="/test" component={TestPage} />

*/

/*

        <Route exact path="/" render={(props) => (<HomePage props={props}/>)} />
          <Route exact path="/museums" render={(props) => (<SearchPage props={props}/>)} />
          <Route exact path="/museums/:museumId" render={(props) => (<DetailsPage/>)} />
          <Route exact path="/categories" render={(props) => (<CategoriesPage isCategories={true} />)} />
          <Route exact path="/counties" render={(props) => (<CategoriesPage isCategories={false} />)} />
          <Route exact path="/list" render={(props) => (<ListPage props={props}/>)} />
          <Route exact path="/test" render={(props) => (<TestPage props={props}/>)} />
         
          <Route component={NotFoundPage} />

*/

export default Routes;
