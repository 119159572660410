import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import React, { useEffect, useMemo, useState } from "react";
import { ThemeProvider } from "styled-components";
import { useHistory } from "react-router-dom";
import "./App.css";
import BaseStyles from "./components/Theme/base-styles";
import theme, { muiTheme } from "./components/Theme/theme";
import Routes from "./routes";
import { Store } from "./Store";
import { KnService } from "./api/KulturNav/KnService";
import { getDistanceBetweenPoints } from "./helpers/geoHelpers";
import usePosition from "./hooks/usePosition";
import useList from "./hooks/useList";
import { StylesProvider } from "@material-ui/core/styles";

const knService = new KnService();

const addDistanceToList = (list, pos) => {
	const sufficientData =
		pos.lat &&
		typeof pos.lat === "number" &&
		pos.lng &&
		typeof pos.lng === "number" &&
		list.length > 0;
	if (!sufficientData) {
		return list;
	}
	const haveDefaultValue = pos.lat === 61 && pos.lng === 10;
	if (haveDefaultValue) {
		console.warn(
			"Did not get user location. Distances to museums not calculated.",
		);
		return list;
	}
	if (list.find(item => item.distance)) {
		return list;
	}
	return list.map(currentMuseum => {
		if (!currentMuseum.position.lat || !currentMuseum.position.lng)
			return currentMuseum;
		const distance = getDistanceBetweenPoints(
			pos.lat,
			pos.lng,
			currentMuseum.position.lat,
			currentMuseum.position.lng,
		);
		return { ...currentMuseum, distance: distance };
	});
};

const App = () => {
	const { dispatch, state } = React.useContext(Store);
	const position = usePosition();
	const museumsList = useList("museums");
	const categoriesList = useList("categories");
	const countiesList = useList("counties");
	const history = useHistory();
	const museumsListWithDistance = useMemo(() => {
		if (!(museumsList.length > 0 && position.lat)) {
			return [];
		}
		return addDistanceToList(museumsList, {
			lat: position.lat,
			lng: position.lng,
		});
	}, [museumsList, position]);

	const dispatchRef = React.useRef();
	const stateRef = React.useRef();

	dispatchRef.current = dispatch;
	stateRef.current = state;

	useEffect(() => {
		const sufficientData =
			Object.entries(categoriesList).length > 0 && countiesList.length > 0;
		if (!sufficientData) {
			return;
		}
		if (museumsListWithDistance.length > 0) {
			dispatchRef.current({
				type: "SET_DATA",
				payload: {
					...stateRef.current.data,
					museumsList: museumsListWithDistance,
					categoriesList,
					countiesList,
				},
			});
		} else {
			dispatchRef.current({
				type: "SET_DATA",
				payload: {
					...stateRef.current.data,
					museumsList: museumsList,
					categoriesList,
					countiesList,
				},
			});
		}
	}, [museumsListWithDistance, categoriesList, countiesList]);

	useEffect(() => {
		const setLang = loc => {
			dispatchRef.current({
				type: "SET_SETTINGS_LOCALE",
				payload: loc,
			});
		};
		let _locale = "";
		const acceptedLanguages = ["no", "nb", "nn", "sv", "se", "en"];
		let languages = window.navigator.languages;
		for (let value of languages) {
			let loc = value.substring(0, 2);
			if (acceptedLanguages.includes(loc)) {
				_locale = loc;
				break;
			}
		}
		setLang(_locale ? _locale : "en");
	}, []);

	useEffect(() => {
		dispatchRef.current({
			type: "SET_USER",
			payload: { ...stateRef.current.user, ...position },
		});
	}, [position]);

	return (
		<>
			<StylesProvider injectFirst>
				<BaseStyles />
				<ThemeProvider theme={theme}>
					<MuiThemeProvider theme={muiTheme}>
						<BrowserRouter history={history}>
							<Routes />
						</BrowserRouter>
					</MuiThemeProvider>
				</ThemeProvider>
			</StylesProvider>
		</>
	);
};

export default App;
