import { Marker, MarkerClusterer } from "@react-google-maps/api";
import equals from "fast-deep-equal";
import * as R from "ramda";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import mapclusterIcon from "../../assets/images/mapclustercircle.svg";

import markerIcon from "../../assets/images/dynamicMarkerIcon.png";
import selectedMarkerIcon from "../../assets/images/selected.png";
import handleDuplicate from "../../helpers/handleDuplicate";
import MapClickBubble from "../MapClickBubble/mapClickBubble";

const MuseumMarkerClusterer = ({ museums, activeMarker, onMarkerClick }) => {
	const [hoverMarker, setHoverMarker] = useState({});
	//const markerRefs = useRef(new Array(museums.length));
	// cluster icons
	const options = {
		/*imagePath:
	  "/node/app/src/assets/images/mapcluster_images/m" //'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
	*/
		styles: [
			{
				anchorIcon: [25, 25],
				width: 50,
				height: 50,
				textSize: 15,
				backgroundPosition: "0px 0px",
				textColor: "#000",
				url: mapclusterIcon,
			},
			/*{
		  width: 80,
		  height: 80,
		  textSize: 15,
		  backgroundPosition: "-8px -8px",
		  textColor: "#000",
		  url: mapclusterIcon
	  }*/
		],
	};

	return (
		<MarkerClusterer options={options} /*averageCenter={true}*/ gridSize={75}>
			{clusterer =>
				handleDuplicate(museums).map((currentMuseum, idx) => {
					const lat = currentMuseum.position.lat;
					const lng = currentMuseum.position.lng;
					const title = R.path(["name", "no"], currentMuseum);
					const uuid = R.path(["uuid"], currentMuseum);

					return (
						<Marker
							//ref={(ref_el)=>{markerRefs.current[idx]=ref_el}}
							clusterer={clusterer}
							key={uuid}
							data-testid={uuid}
							title={title}
							position={{ lat, lng }}
							onClick={evt => {
								//currentMuseum.marker = evt;
								//console.log("markerRefs.current[idx]:")
								//console.dir(markerRefs.current[idx])
								/*console.log(
									"getBoundingClientRect: ",
									evt.domEvent.path[0].getBoundingClientRect(),
								);*/
								//console.log("MARKERCLICK")
								onMarkerClick(currentMuseum);
							}}
							//onMouseOver={() => setHoverMarker(currentMuseum)}
							//onMouseOut={() => setHoverMarker({})}
							uuid={uuid}
							label={{
								text: title,
								fontFamily: "var(--akkurat)",
								fontSize: "12px",
								fontWeight: "600",
								color:
									R.prop("uuid", hoverMarker) === uuid ? "#538cdd" : "#222",
							}}
							icon={{
								labelOrigin:
									R.prop("uuid", activeMarker) === uuid
										? new window.google.maps.Point(28, 49)
										: new window.google.maps.Point(25, 40),
								url:
									R.prop("uuid", activeMarker) === uuid
										? markerIcon
										: markerIcon,
								scaledSize:
									R.prop("uuid", activeMarker) === uuid
										? { width: 34, height: 39 }
										: { width: 26, height: 30 },
							}}
						/>
					);
				})
			}
		</MarkerClusterer>
	);
};
//export default MuseumMarkerClusterer;
export default React.memo(MuseumMarkerClusterer, (a, b) =>
	equals(a.museums, b.museums),
);
