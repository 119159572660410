import React, { useState, useContext } from "react";
import { renderToString } from "react-dom/server";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import {
	MapContainer,
	TileLayer,
	Marker,
	Popup,
	Tooltip,
	useMapEvents,
} from "react-leaflet";
import * as L from "leaflet";
import markerIcon from "../../assets/images/markerIcon.png";
//import MarkerClusterGroup from "react-leaflet-markercluster";

import { Store } from "../../Store";
import { DefaultMarkerStyle } from "../MarkerStyles/markerStyles";




const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const MapContainerCustom = styled(MapContainer)`
	height: 70vh;
	width: 1000px;
	border-radius: 50px;

	.leaflet-tile-pane {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
}
`;

function LocationMarker() {
	const [position, setPosition] = useState(null);
	const map = useMapEvents({
		click() {
			map.locate();
		},
		locationfound(e) {
			setPosition(e.latlng);
			map.flyTo(e.latlng, map.getZoom());
		},
	});

	return position === null ? null : (
		<Marker position={position}>
			<Popup>You are here</Popup>
		</Marker>
	);
}



const OpenMap = () => {
	const { state } = useContext(Store);

	return (
		<Container>
			<Helmet>
				<link
					rel="stylesheet"
					href="https://unpkg.com/leaflet/dist/leaflet.css"
				/>

				<link
					rel="stylesheet"
					href="https://unpkg.com/react-leaflet-markercluster/dist/styles.min.css"
				/>
			</Helmet>
			<MapContainerCustom
				center={[59.91457, 10.73614]}
				zoom={13}
				maxZoom={18}
				scrollWheelZoom={true}
			>
				<MarkerClusterGroup>
					{state.data.museumsList.length &&
						state.data.museumsList.map(museum => {
							const iconHtml = renderToString(
								<DefaultMarkerStyle name={museum.name["no"]} />,
							);
							if (museum.position.lat) {
								return (
									<Marker
										key={museum.uuid}
										position={[museum.position.lat, museum.position.lng]}
										icon={L.icon({
											className: "custom-icon",
											iconUrl: markerIcon,
											iconSize: 30,
											//html: iconHtml,
											iconAnchor: [15, 30],
										})}
									>
										<Tooltip direction="bottom" permanent={true} opacity={0.9}>
											{museum.name["no"]}
										</Tooltip>
									</Marker>
								);
							}
							console.log(museum);
						})}
				</MarkerClusterGroup>
				<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
			</MapContainerCustom>
		</Container>
	);
};

export default OpenMap;
