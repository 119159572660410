/*import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';*/
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import useWindowDimensions from "../../hooks/useWindowDimensions";

//import { ReactComponent as IconCategories } from "../../assets/images/header_icons/categories.svg";
//import { ReactComponent as IconMapMarker } from "../../assets/images/header_icons/nav-marker.svg";
//import { ReactComponent as IconHome } from "../../assets/images/header_icons/home.svg";
import { ReactComponent as LogoMG } from "../../assets/images/header_icons/mg-logo-med-symbol.svg"; /*"../../assets/images/header_icons/museumsguiden_logoforslag_path_plain.svg";*/
//import { ReactComponent as IconList } from "../../assets/images/header_icons/list-left.svg";

import styled from "styled-components";
import NavButtonGroup from "../NavButtonGroup/navButtonGroup";
import { Button } from "@material-ui/core";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";

const drawerWidth = 240;
const smallWidth = 880;

/*
  TODO:

  - Decide drawer design.
  - Add css breakpoint for middle sized screens to prevent oveflow issues.
  

*/

const Container = styled.div`
	display: flex;
	background-color: var(--Museumsbakgrunn);
	min-height: 85px;
	align-items: center;
	justify-content: space-between;
	padding-left: 0px;
	padding-right: 80px;
	width: 100%;
	box-sizing: border-box;

	@media only screen and (max-width: ${smallWidth - 1}px) {
		//display: none;
		height: 39px;
		min-height: 39px;
		padding-left: 0px;
		padding-right: 0px;
		justify-content: center;
	}
`;

const NavTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	@media only screen and (max-width: ${smallWidth - 1}px) {
		transform: scale(0.7);
	}
`;

const NavButtonContainer = styled.div`
	width: 500px;
	@media only screen and (max-width: ${smallWidth - 1}px) {
		display: none;
	}
`;

const LogoButton = styled(Button)`
	padding-left: ${props =>
		props.currentPage === "/museums" ? "24px" : "80px"};
	padding-right: ${props =>
		props.currentPage === "/museums" ? "24px" : "80px"};
`;

const CustomLogo = styled(LogoMG)`
	height: 30px;
	//width: fit-content;
`;

const Header = () => {
	const history = useHistory();
	//const { width } = useWindowDimensions();
	//const { t } = useTranslation('common');
	//const classes = useStyles({ isWide: width > smallWidth });
	//const [prevPath,setPrevPath] = useState("");

	useGoogleAnalytics();

	return (
		<Container>
			<NavTitle>
				<LogoButton
					onClick={() => {
						history.push({ pathname: `/` });
					}}
					currentPage={history.location.pathname}
				>
					<CustomLogo />
					{/*console.log("headerHistory: ", history)*/}
				</LogoButton>
			</NavTitle>
			<NavButtonContainer>
				<NavButtonGroup />
			</NavButtonContainer>
		</Container>
	);
};

export default Header;
