import { translate } from "../hooks/useFallbackTrans";

export const filterByDistance = museumsList => {
	console.group("filterByDistance");
	console.log(museumsList[38]);
	console.groupEnd();
	return null;
};

// returns list of museums filtered by categories
export const filterByCategories = (
	museumsList,
	categoriesList,
	filterCategories,
) => {
	// No need to filter if all categories are selected
	if (filterCategories[0] === "all" || filterCategories.length < 1) {
		return museumsList;
	}

	return museumsList.filter(currentMuseum => {
		for (let category of filterCategories) {
			//console.log(categoriesList[category]);
			const subCategories = categoriesList[category].subCategories;

			for (let museumCategory of currentMuseum.categories) {
				if (museumCategory in subCategories) {
					return true;
				}
			}
		}

		return false;
	});
};

// returns list of museums filtered by categories and counties
export const filterByCategoriesAndCounties = (
	museumsList,
	categoriesList,
	filterCategories,
	filterCounties,
) => {
	// No need to filter if all or no categories are selected
	if (
		(filterCategories[0] === "all" || filterCategories.length < 1) &&
		(filterCounties[0] === "all" || filterCounties.length < 1)
	) {
		return museumsList;
	}

	return museumsList.filter(currentMuseum => {
		let categoriesTrue = false;
		let countiesTrue = false;

		if (!(filterCategories[0] === "all" || filterCategories.length < 1)) {
			for (let category of filterCategories) {
				const subCategories = categoriesList[category].subCategories;

				for (let museumCategory of currentMuseum.categories) {
					if (museumCategory in subCategories) {
						categoriesTrue = true;
					}
				}
			}
		} else {
			categoriesTrue = true;
		}

		if (filterCounties[0] === "all" || filterCounties.length < 1) {
			countiesTrue = true;
		} else {
			if (filterCounties.includes(currentMuseum.county)) {
				countiesTrue = true;
			}
		}

		if (categoriesTrue && countiesTrue) {
			return true;
		} else {
			return false;
		}
	});
};

// Sort categories. Other should always be last.
export const sortCategories = (
	categories,
	useShortName = false,
	locale = "no",
) => {
	const otherCategoryValue = "a2ce370b-e955-4a1d-bbfb-3f1a375cdbd2";
	const categoryNameField = useShortName ? "alternativeName" : "displayValue";
	categories.sort((a, b) => {
		const aValue = translate(a[categoryNameField], locale); // OBS BØR LEGGE TIL LOKALISERING
		const bValue = translate(b[categoryNameField], locale); // OBS BØR LEGGE TIL LOKALISERING

		// annet kategorien skal alltid være nederst
		if (a.value === otherCategoryValue) return 1;
		if (b.value === otherCategoryValue) return -1;

		if (aValue < bValue) return -1;
		if (aValue > bValue) return 1;
		return 0;
	});

	return categories;
};

export const sortCounties = (counties, locale = "no") => {
	counties.sort((a, b) => {
		const aValue = translate(a.name, locale);
		const bValue = translate(b.name, locale);
		if (aValue < bValue) return -1;
		if (aValue > bValue) return 1;
		return 0;
	});

	return counties;
};

export const updateCheckboxes = (
	categoriesList,
	filterState,
	valueVarName = "value",
) => {
	const checkerState_tmp = {};
	Object.values(categoriesList).forEach(currentCategory => {
		if (filterState.includes(currentCategory[valueVarName])) {
			checkerState_tmp[currentCategory[valueVarName]] = true;
		} else {
			checkerState_tmp[currentCategory[valueVarName]] = false;
		}
	});

	return checkerState_tmp;
};

export const sortItemsRecursiveByKeys = (items, keys) => {
	if (!items) {
		return [];
	}
	const reduceObj = obj => {
		return keys.reduce((prev, cur) => prev[cur], obj);
	};
	let _validItems = items.filter(x => x && reduceObj(x));
	let _nonValidItems = items.filter(x => !(x && reduceObj(x)));
	_validItems.sort((a, b) => {
		let v1 =
			typeof reduceObj(a) === "string"
				? reduceObj(a).toUpperCase()
				: reduceObj(a);
		let v2 =
			typeof reduceObj(b) === "string"
				? reduceObj(b).toUpperCase()
				: reduceObj(b);
		if (v1 < v2) {
			return -1;
		}
		return v1 > v2 ? 1 : 0;
	});
	return [..._validItems, ..._nonValidItems].map(x => {
		x.items = sortItemsRecursiveByKeys(x.items, keys);
		return x;
	});
};
